import React from "react"

import Layout from "../components/layout/layout"
import RecipeForm from "../components/create/form"
import SEO from "../components/layout/seo"

const CreatePage = () => (
  <Layout>
    <div className="container e_create">
      <SEO title="Create recipe" />
      <h1 className="mt-4">Create a new recipe</h1>
      <RecipeForm />
    </div>
  </Layout>
)

export default CreatePage
