import React, { Component } from "react"

import IngredientFields from "./ingredientFields"
import InstructionFields from "./instructionFields"

var moment = require("moment")

export default class RecipeForm extends Component {
  constructor({ match }) {
    super()
    this.state = {
      options: {
        recipeTypeOptions: [
          { value: "Breakfast", label: "Breakfast" },
          { value: "Dinner", label: "Dinner" },
          { value: "Side", label: "Side" },
          { value: "Sweet", label: "Sweet" },
        ],
      },
      recipeData: {
        name: "",
        recipeImage: "",
        recipeType: "Breakfast",
        cuisineType: "",
        cookingTime: 5,
        serves: 4,
        source: "",
        note: "",
        createdAt: moment().format(),
        ingredients: [
          {
            what: "",
          },
        ],
        instructions: [
          {
            details: "",
          },
        ],
        cooked: true,
        lastCooked: moment().format("YYYY-MM-DD"),
        cookingNotes: [
          {
            date: moment().format("YYYY-MM-DD"),
            score: 5,
            comment: "",
          },
        ],
      },
    }
  }

  onChange = e => {
    const target = e.target
    let value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    if (target.type === "number") {
      value = parseInt(value)
    }

    const state = this.state
    if (name === "score" || name === "comment") {
      state.recipeData.cookingNotes[0][name] = value
    } else {
      state.recipeData[name] = value
    }
    this.setState(state, () => {
      if (name === "name") {
        this.updateRecipeImage()
      }
    })
  }

  onInstructionsChange = (e, index) => {
    const state = this.state
    state.recipeData.instructions[index].details = e.target.value
    this.setState(state)
  }

  addInstruction = e => {
    e.preventDefault()
    let state = this.state
    state.recipeData.instructions.push({ details: "" })
    this.setState(state)
  }

  removeInstructionAt = (e, index) => {
    e.preventDefault()
    let state = this.state
    let instructions = this.state.recipeData.instructions
    instructions.splice(index, 1)
    state.recipeData.instructions = instructions
    this.setState(state)
  }

  onIngredientChange = (e, prop, index) => {
    const state = this.state
    let value = e.target.value
    if (prop === "calories") {
      value = parseFloat(value)
    }
    state.recipeData.ingredients[index][prop] = value
    this.setState({ state }, () => {
      // if (prop === "calories") {
      //   this.updateCalories()
      // }
    })
  }

  addIngredient = e => {
    e.preventDefault()
    let state = this.state
    state.recipeData.ingredients.push({
      what: "",
      // calories: null,
    })
    this.setState(state)
  }

  removeIngredientAt = (e, index) => {
    e.preventDefault()
    let state = this.state
    let ingredients = this.state.recipeData.ingredients
    ingredients.splice(index, 1)
    state.recipeData.ingredients = ingredients
    this.setState({ state }, () => this.updateCalories())
  }

  updateCalories = () => {
    let state = this.state
    let calorieCounter = this.state.recipeData.ingredients.reduce(
      (accumulator, ingredient) => accumulator + ingredient.calories,
      0
    )
    state.recipeData.calories = calorieCounter
    this.setState(state)
  }

  updateRecipeImage = () => {
    let state = this.state
    state.recipeData.recipeImage = `${state.recipeData.name
      .replace(/\s+/g, "-")
      .replace(/'/g, "")
      .replace(/,/g, "")
      .toLowerCase()}.jpg`
    this.setState(state)
  }

  render() {
    let ingredients = this.state.recipeData.ingredients.map(
      (ingredient, index) => {
        return (
          <IngredientFields
            what={ingredient.what}
            key={index}
            index={index}
            onIngredientChange={this.onIngredientChange}
            removeIngredientAt={this.removeIngredientAt}
          />
        )
      }
    )

    let instructions = this.state.recipeData.instructions.map(
      (instruction, index) => {
        return (
          <InstructionFields
            details={instruction.details}
            key={index}
            index={index}
            onInstructionsChange={this.onInstructionsChange}
            removeInstructionAt={this.removeInstructionAt}
          />
        )
      }
    )

    return (
      <div className="row">
        <div className="col-9">
          <form onSubmit={this.onSubmit} encType="multipart/form-data">
            <h2 className="mt-4">Recipe details</h2>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                Name
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={this.state.recipeData.name}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="recipeType">
                Recipe type
              </label>
              <div className="col-sm-10">
                <select
                  name="recipeType"
                  id="recipeType"
                  className="form-control"
                  defaultValue={this.state.recipeData.recipeType}
                  onBlur={this.onChange}
                >
                  {this.state.options.recipeTypeOptions.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="cuisineType">
                Cuisine type
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="cuisineType"
                  id="cuisineType"
                  value={this.state.recipeData.cuisineType}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="cookingTime">
                Cooking time
              </label>
              <div className="col-sm-10">
                <input
                  type="number"
                  step="5"
                  name="cookingTime"
                  id="cookingTime"
                  value={this.state.recipeData.cookingTime}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="serves">
                Serves
              </label>
              <div className="col-sm-10">
                <input
                  type="number"
                  step="1"
                  name="serves"
                  id="serves"
                  value={this.state.recipeData.serves}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="source">
                Source
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="source"
                  id="source"
                  value={this.state.recipeData.source}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="note">
                Note
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="note"
                  id="note"
                  value={this.state.recipeData.note}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            </div>

            <h2 className="mt-5">Ingredients</h2>
            <ol className="pl-3">{ingredients}</ol>
            <button className="btn btn-success" onClick={this.addIngredient}>
              Add another ingredient
            </button>

            <h2 className="mt-5">Instructions</h2>
            <ol className="pl-3">{instructions}</ol>
            <button className="btn btn-success" onClick={this.addInstruction}>
              Add another instruction
            </button>

            <h2 className="mt-5">Cooked note</h2>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="score">
                Score
              </label>
              <div className="col-sm-10">
                <input
                  type="number"
                  step="1"
                  min="1"
                  max="5"
                  name="score"
                  id="score"
                  value={this.state.recipeData.cookingNotes[0].score}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="comment">
                Comment
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="comment"
                  id="comment"
                  value={this.state.recipeData.cookingNotes[0].comment}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="col-3">
          <textarea
            value={this.state.recipeData.recipeImage}
            className="w-100 form-control mb-3"
            readOnly
          ></textarea>
          <textarea
            value={
              this.state.recipeData.name
                .replace(/\s+/g, "-")
                .replace(/'/g, "")
                .replace(/,/g, "")
                .toLowerCase() + ".json"
            }
            className="w-100 form-control mb-3"
            readOnly
          ></textarea>
          <textarea
            value={JSON.stringify(this.state.recipeData, undefined, 2)}
            className="w-100 h-100 form-control"
            readOnly
          ></textarea>
        </div>
      </div>
    )
  }
}
