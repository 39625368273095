import React from "react"
import PropTypes from "prop-types"

const InstructionFields = props => (
  <li className="pl-3 mb-2">
    <div className="form-row">
      <textarea
        value={props.details}
        className="form-control col-8 mr-1"
        onChange={e => props.onInstructionsChange(e, props.index)}
      />
      <div className="col-2">
        <button
          className="btn btn-danger"
          onClick={e => props.removeInstructionAt(e, props.index)}
        >
          x
        </button>
      </div>
    </div>
  </li>
)

InstructionFields.propTypes = {
  details: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onInstructionsChange: PropTypes.func.isRequired,
  removeInstructionAt: PropTypes.func.isRequired,
}

export default InstructionFields
