import React from "react"
import PropTypes from "prop-types"

const IngredientFields = props => (
  <li className="pl-3 mb-2">
    <div className="form-row">
      <input
        className="form-control col-9 mr-2"
        type="text"
        placeholder="what"
        name="ingredientWhat"
        onChange={e => props.onIngredientChange(e, "what", props.index)}
      />
      <div className="col-2">
        <button
          className="btn btn-danger"
          onClick={e => props.removeIngredientAt(e, props.index)}
        >
          x
        </button>
      </div>
    </div>
  </li>
)

IngredientFields.propTypes = {
  size: PropTypes.string,
  thing: PropTypes.string,
  type: PropTypes.string,
  index: PropTypes.number,
  onIngredientChange: PropTypes.func.isRequired,
  removeIngredientAt: PropTypes.func.isRequired,
}

export default IngredientFields
